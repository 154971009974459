import * as React from 'react'
import { formatConvenient } from '../../../utils/date'
import { translate } from '../../../utils/translations'
import * as translations from './translations'

export const LiveUpdate = ({ lastUpdated }: { lastUpdated: number }) => {
  const [, rerender] = React.useState<boolean>(true)
  const interval = React.useRef<NodeJS.Timeout>()
  React.useEffect(() => {
    interval.current = setInterval(() => {
      rerender(prev => !prev)
    }, 1000)
    return () => {
      if (interval.current) {
        clearInterval(interval.current)
      }
    }
  }, [rerender])
  return (
    <>
      {translate({ translations, path: ['refreshed'] })}: {formatConvenient(new Date(lastUpdated))}
    </>
  )
}
