import * as React from 'react'
import styles from './styles.module.css'
import isetorii from './isetorii.gif'
import inaritorii from './inaritorii.gif'
import kasugatorii from './kasugatorii.gif'
import sannoutorii from './sannoutorii.gif'
import { useWebDescription, useWebTitle } from '../hooks'
import { Page } from '../../elementals/Page'

export const Torii = () => {
  useWebTitle('torii - 鳥居')
  useWebDescription('All about torii')
  return (
    <Page>
      <div className={styles.toriiPage}>
        <div className={styles.headerContainer}>
          <div className={styles.header}>
            <CJKExplanation
              value={[
                ['鳥', 'tori'],
                ['居', 'i'],
              ]}
              source="http://www.aisf.or.jp/~jaanus/deta/t/torii.htm"
            />
          </div>
        </div>
        <div className={styles.content}>
          {toriiData.map(
            ({ title, image: [imageSrc, imageWidth, imageHeight], descriptionParts }, index) => (
              <div key={index} className={styles.box}>
                <span className={styles.title}>
                  <CJKExplanation value={title.text} source={title.source} />
                </span>
                <img src={imageSrc} alt={title.name} width={imageWidth} height={imageHeight} />
                <ol className={styles.descriptionList}>
                  {descriptionParts.map((description, index) => (
                    <li key={index}>
                      <CJKExplanation value={description.text} source={description.source} />
                    </li>
                  ))}
                </ol>
              </div>
            ),
          )}
        </div>
      </div>
    </Page>
  )
}

const CJKExplanation = ({
  value,
  source,
}: {
  value: Array<CJKPronunciationInfo>
  source: string
}) => (
  <>
    {value.map(([japaneseCharacter, latinPronounciation], index) => {
      const LinkComponent = source ? 'a' : React.Fragment
      return (
        <ruby key={index}>
          {japaneseCharacter}
          <rt>
            <LinkComponent href={source}>{latinPronounciation}</LinkComponent>
          </rt>
        </ruby>
      )
    })}
  </>
)

type CJKPronunciationInfo = [string, string]
type ToriiData = {
  title: { name: string; source: string; text: Array<CJKPronunciationInfo> }
  image: [string, number, number]
  descriptionParts: Array<{ source: string; text: Array<CJKPronunciationInfo> }>
}

const toriiData: Array<ToriiData> = [
  {
    title: {
      name: 'ise-torii',
      source: 'http://www.aisf.or.jp/~jaanus/deta/i/isetorii.htm',
      text: [
        ['伊', 'i'],
        ['勢', 'se'],
        ['鳥', 'tori'],
        ['居', 'i'],
      ],
    },
    image: [isetorii, 241, 136],
    descriptionParts: [
      {
        source: 'http://www.aisf.or.jp/~jaanus/deta/k/kasagi.htm',
        text: [
          ['笠', 'kasa'],
          ['木', 'gi'],
        ],
      },
      { source: 'http://www.aisf.or.jp/~jaanus/deta/k/kusabi.htm', text: [['楔', 'kusabi']] },
      { source: 'http://www.aisf.or.jp/~jaanus/deta/n/nuki.htm', text: [['貫', 'nuki']] },
      { source: 'http://www.aisf.or.jp/~jaanus/deta/h/hashira.htm', text: [['柱', 'hashira']] },
    ],
  },
  {
    title: {
      name: 'kasuga-torii',
      source: 'http://www.aisf.or.jp/~jaanus/deta/k/kasugatorii.htm',
      text: [
        ['春', 'kasu'],
        ['日', 'ga'],
        ['鳥', 'tori'],
        ['居', 'i'],
      ],
    },
    image: [kasugatorii, 220, 130],
    descriptionParts: [
      {
        source: 'http://www.aisf.or.jp/~jaanus/deta/k/kasagi.htm',
        text: [
          ['笠', 'kasa'],
          ['木', 'gi'],
        ],
      },
      {
        source: 'http://www.aisf.or.jp/~jaanus/deta/s/shimaki.htm',
        text: [
          ['島', 'shima'],
          ['木', 'ki'],
        ],
      },
      { source: 'http://www.aisf.or.jp/~jaanus/deta/n/nuki.htm', text: [['貫', 'nuki']] },
      { source: 'http://www.aisf.or.jp/~jaanus/deta/k/kusabi.htm', text: [['楔', 'kusabi']] },
      {
        source: '',
        text: [
          ['額', 'gaku'],
          ['束', 'zuka'],
        ],
      },
    ],
  },
  {
    title: {
      name: 'inari-torii',
      source: 'http://www.aisf.or.jp/~jaanus/deta/i/inaritorii.htm',
      text: [
        ['稲', 'ina'],
        ['荷', 'ri'],
        ['鳥', 'tori'],
        ['居', 'i'],
      ],
    },
    image: [inaritorii, 212, 130],
    descriptionParts: [
      {
        source: 'http://www.aisf.or.jp/~jaanus/deta/k/kasagi.htm',
        text: [
          ['笠', 'kasa'],
          ['木', 'gi'],
        ],
      },
      {
        source: 'http://www.aisf.or.jp/~jaanus/deta/s/shimaki.htm',
        text: [
          ['島', 'shima'],
          ['木', 'ki'],
        ],
      },
      {
        source: 'http://www.aisf.or.jp/~jaanus/deta/d/daiwa.htm',
        text: [
          ['台', 'dai'],
          ['輪', 'wa'],
        ],
      },
      { source: 'http://www.aisf.or.jp/~jaanus/deta/n/nuki.htm', text: [['貫', 'nuki']] },
      { source: 'http://www.aisf.or.jp/~jaanus/deta/k/kusabi.htm', text: [['楔', 'kusabi']] },
      {
        source: '',
        text: [
          ['額', 'gaku'],
          ['束', 'zuka'],
        ],
      },
      {
        source: 'http://www.aisf.or.jp/~jaanus/deta/k/kamebara.htm',
        text: [
          ['亀', 'kame'],
          ['腹', 'bara'],
        ],
      },
    ],
  },
  {
    title: {
      name: 'sannou-torii',
      source: 'http://www.aisf.or.jp/~jaanus/deta/s/sannoutorii.htm',
      text: [
        ['山', 'sann'],
        ['王', 'ou'],
        ['鳥', 'tori'],
        ['居', 'i'],
      ],
    },
    image: [sannoutorii, 211, 156],
    descriptionParts: [
      {
        source: 'http://www.aisf.or.jp/~jaanus/deta/s/shimaki.htm',
        text: [
          ['島', 'shima'],
          ['木', 'ki'],
        ],
      },
      {
        source: 'http://www.aisf.or.jp/~jaanus/deta/k/kasagi.htm',
        text: [
          ['笠', 'kasa'],
          ['木', 'gi'],
        ],
      },
      {
        source: 'http://www.aisf.or.jp/~jaanus/deta/u/uragou.htm',
        text: [
          ['裏', 'ura'],
          ['甲', 'gou'],
        ],
      },
      {
        source: '',
        text: [
          ['破', 'ha'],
          ['風', 'fu'],
        ],
      },
      {
        source: '',
        text: [
          ['烏', 'u'],
          ['頭', 'tou'],
        ],
      },
      {
        source: '',
        text: [
          ['棟', 'muna'],
          ['束', 'zuka'],
        ],
      },
      {
        source: '',
        text: [
          ['額', 'gaku'],
          ['束', 'zuka'],
        ],
      },
      { source: 'http://www.aisf.or.jp/~jaanus/deta/k/kusabi.htm', text: [['楔', 'kusabi']] },
      { source: 'http://www.aisf.or.jp/~jaanus/deta/n/nuki.htm', text: [['貫', 'nuki']] },
      { source: 'http://www.aisf.or.jp/~jaanus/deta/h/hashira.htm', text: [['柱', 'hashira']] },
      {
        source: 'http://www.aisf.or.jp/~jaanus/deta/n/nemaki.htm',
        text: [
          ['根', 'ne'],
          ['巻', 'maki'],
        ],
      },
    ],
  },
]
