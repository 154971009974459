import * as React from 'react'

type TextTag = 'span' | 'label' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5'

type Props<Tag extends TextTag> = React.ComponentProps<Tag> & {
  tag?: Tag
}

export const Text = <Tag extends TextTag>({ tag, title, style, ...props }: Props<Tag>) => {
  const Component = (tag ?? 'span') as any
  return (
    <Component
      {...props}
      title={title}
      style={{
        ...(title ? { textDecoration: 'underline dotted', cursor: 'help' } : undefined),
        ...style,
      }}
    />
  )
}
