import { get } from 'lodash'
import { getLanguage } from './locale'

const getTranslationLanguage = <Translation, T extends { de: Translation; en: Translation }>(
  translations: T,
): Translation => translations[getLanguage()]

type Props<Translation extends object, TranslationParameter> = {
  translations: { en: Translation; de: Translation }
  path: Array<string>
  passed?: TranslationParameter
}

export const translate = <Translation extends object, TranslationParameter>({
  translations,
  path,
  passed,
}: Props<Translation, TranslationParameter>) => {
  const translation = get(getTranslationLanguage(translations), path)
  if (typeof translation === 'function') {
    return translation(passed)
  }
  return translation
}
