import * as React from 'react'
import { Text } from '../../elementals/Text'
import { translate } from '../../../utils/translations'
import * as translations from './translations'
import { colors } from './colors'
import { xhr } from '../../../xhr'
import { useHashParam } from './use-hash-param'

export const usernames = [
  'Gerald Fröhlich',
  'Silvia Eiletz',
  'Christoph Wohlmuther',
  'Sabine Schreiner',
  'Christian Laistler',
  'Daniela Peter',
  'Daniel Bartsch',
  'Michaela Berger',
  'Markus Wagner-Berger',
  'Daniela Förster-Romswinkel',
  'Dennis Hochmayr-Ruzicska',
  'Rosa Pirkner',
  'Klemens Pilz',
  'Amalia Hinterkörner',
  'Rudolf Forstner',
  'Ingrid Ratzinger',
  'Johann Fuß',
  'Christina Penitz',
  'Bernhard Mauler',
  'Bettina Wisser',
  'Georg Hinterkörner',
  'Katharina Ruzicska',
  'Stephan Scherer',
  'Eva Fröhlich-Graschopf',
  'Christian Haslinger',
]

type Props = {
  setAuthToken: React.Dispatch<React.SetStateAction<string | undefined>>
}

export const Login = ({ setAuthToken }: Props) => {
  React.useEffect(() => {
    document.title = 'Login'
  }, [])

  const [, setUsername] = useHashParam('user')

  const [password, setPassword] = React.useState('')
  const [inputUsername, setInputUsername] = React.useState('')
  const [loginError, setLoginError] = React.useState(false)
  const showLoginError = loginError && (inputUsername === '' || password === '')

  const handleSubmit = () => {
    xhr
      .post('/api/voters/login', { username: inputUsername, password })
      .then(({ data }) => {
        setAuthToken(data.token)
        setUsername(inputUsername)
      })
      .catch(error => {
        if ('response' in error && error.response.status === 401) {
          setInputUsername('')
          setPassword('')
          setLoginError(true)
        }
      })
  }

  return (
    <dialog
      style={{
        backgroundColor: colors.pageBackground,
        color: colors.text.default,
        zoom: '150%',
      }}
      open
    >
      <form method="dialog">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: 4 }}>
          <select
            style={{ color: colors.text.default }}
            value={inputUsername}
            onChange={event => {
              setInputUsername(event.target.value)
            }}
          >
            <option value={''}>{translate({ translations, path: ['gwUser'] })}</option>
            {usernames.map(name => (
              <option key={name} value={name}>
                {name}
              </option>
            ))}
          </select>
          <input
            type="password"
            value={password}
            style={{ width: '100%' }}
            onChange={event => setPassword(event.target.value)}
            placeholder={translate({ translations, path: ['password'] })}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                handleSubmit()
              }
            }}
          />
          {showLoginError && (
            <Text style={{ color: colors.text.besuchenUnnötig, fontSize: '0.75em' }}>
              {translate({ translations, path: ['gwLoginError'] })}
            </Text>
          )}
          <button
            type="button"
            disabled={inputUsername === ''}
            style={{ width: '50%' }}
            onClick={handleSubmit}
          >
            OK
          </button>
        </div>
      </form>
    </dialog>
  )
}

const AuthContext = React.createContext<string | undefined>(undefined)

export const AuthContextProvider = AuthContext.Provider
export const useAuthToken = () => React.useContext(AuthContext)
