import { getLocale } from './locale'

const browserLocale = getLocale()

export const toDate = (dateStr: string): Date => new Date(dateStr)

// const dateFormat = new Intl.DateTimeFormat([], { dateStyle: 'medium' } as any)
// const formatDate = (date: Date) => dateFormat.format(date) // eg. 24.12.2020

const timeFormat = new Intl.DateTimeFormat(browserLocale, { timeStyle: 'short' } as any)
const formatTime = (date: Date) => timeFormat.format(date) // eg. 13:00

const dateTimeFormat = new Intl.DateTimeFormat(browserLocale, {
  dateStyle: 'medium',
  timeStyle: 'short',
} as any)
const formatDateTime = (date: Date) => dateTimeFormat.format(date) // eg. 24.12.2020, 13:00:55

export const formatExact = (date: Date, now: Date = new Date()) => {
  const dateMs = date.getTime()
  const nowMs = now.getTime()
  const isToday = dateMs > nowMs - DAY_MILLISECONDS && dateMs < nowMs + DAY_MILLISECONDS
  return isToday ? formatTime(date) : formatDateTime(date)
}

const DAY_MILLISECONDS = 86400000
export const formatConvenient = (date: Date, now: Date = new Date()) =>
  // only use relative time if it's less than 1.5 years ago/away
  date.getTime() > now.getTime() - DAY_MILLISECONDS * 365 * 1.5 ||
  date.getTime() < now.getTime() + DAY_MILLISECONDS * 365 * 1.5
    ? formatRelative(date, now)
    : formatDateTime(date)

const formatRelative = (date: Date, relativeTo: Date = new Date()) => {
  const seconds = (date.getTime() - relativeTo.getTime()) / 1000

  if (Math.abs(seconds) < 10) {
    return relativeTime.format(0, 'second') // "now"
  }
  const [unit, unitSeconds] =
    unitsSeconds.find(([, unitSeconds]) => Math.abs(seconds) >= unitSeconds) ??
    (['second', 1] as const)

  const relativeUnit = seconds / unitSeconds
  return relativeTime.format(
    relativeUnit < 0 ? Math.ceil(relativeUnit) : Math.floor(relativeUnit),
    unit,
  )
}

const pad2 = (number: number) => String(number < 10 ? '0' + number : number)

export const formatDateForInput = (date: Date) =>
  [date.getFullYear(), pad2(date.getMonth() + 1), pad2(date.getDate())].join('-')

export const formatTimeForInput = (date: Date) =>
  [pad2(date.getHours()), pad2(date.getMinutes())].join(':')

const unitsSeconds = [
  ['year', 60 * 60 * 24 * 365],
  // ['quarter', 60 * 60 * 24 * 90], // nah
  ['month', 60 * 60 * 24 * 30],
  ['week', 60 * 60 * 24 * 7],
  ['day', 60 * 60 * 24],
  ['hour', 60 * 60],
  ['minute', 60],
] as const

const relativeTime = new Intl.RelativeTimeFormat(browserLocale, {
  style: 'long',
  numeric: 'auto',
})
