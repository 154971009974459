import * as React from 'react'
import { AuthContextProvider, Login } from './login'
import { Page } from './page'

export const VoterTodo = () => {
  const [authToken, setAuthToken] = React.useState<string>()

  if (!authToken) {
    return <Login setAuthToken={setAuthToken} />
  }

  return (
    <AuthContextProvider value={authToken}>
      <Page />
    </AuthContextProvider>
  )
}
