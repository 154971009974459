import * as React from 'react'
import { Text } from '../../elementals/Text'
import { translate } from '../../../utils/translations'
import * as translations from './translations'
import { colors } from './colors'
import { Line } from './line'
import { useHashParam } from './use-hash-param'
import { Header } from './header'
import { Voter } from './types'
import { xhr } from '../../../xhr'
import { useAuthToken } from './login'

const useVoters = (authToken: string | undefined) => {
  const [{ data, lastUpdated }, setData] = React.useState<{
    data: Array<Voter> | undefined
    lastUpdated: number
  }>({ data: undefined, lastUpdated: 0 })

  const trigger = React.useCallback(async () => {
    if (authToken) {
      const response = await xhr.get('/api/voters', {
        headers: {
          Authorization: 'Bearer ' + authToken,
        },
      })
      setData({ data: response.data, lastUpdated: new Date().valueOf() })
    }
  }, [setData, authToken])

  React.useEffect(() => {
    trigger()
  }, [trigger])

  return {
    data,
    lastUpdated,
    isLoading: data === undefined,
    retrigger: trigger,
  }
}

export const Page = () => {
  React.useEffect(() => {
    document.title = translate({ translations, path: ['gw'] })
  }, [])

  const authToken = useAuthToken()

  const { data: allData, lastUpdated, retrigger } = useVoters(authToken)
  const [username] = useHashParam('user')
  const [ort, setOrt] = useHashParam('ort')

  const [showDone, setShowDone] = React.useState(true)
  const [showReserved, setShowReserved] = React.useState(true)

  const myReservationsText = translate({ translations, path: ['myReservations'] })

  let doIHaveReservations = false
  const orte = allData
    ? [myReservationsText].concat(
        allData.reduce((acc, { ort, reserviertVon }) => {
          if (reserviertVon === username) {
            doIHaveReservations = true
          }
          return acc.includes(ort) ? acc : acc.concat(ort)
        }, [] as Array<string>),
      )
    : []

  const selectedOrt = ort ?? (doIHaveReservations ? orte[0] : orte[1])

  const ortData = allData?.filter(({ ort, reserviertVon }) =>
    selectedOrt === myReservationsText ? reserviertVon === username : ort === selectedOrt,
  )

  const ortDataForUser = ortData?.filter(
    ({ istAbgehandeltWorden, reserviertVon }) =>
      (showDone ? true : !istAbgehandeltWorden) &&
      (showReserved ? true : [null, '', username].includes(reserviertVon)),
  )

  const openPeople = ortData?.filter(({ istAbgehandeltWorden }) => istAbgehandeltWorden === 0)
  const isOrtDone = openPeople?.length === 0
  const isRestReserved =
    (openPeople?.length ?? 0) > 0 &&
    openPeople?.every(
      ({ reserviertVon }) =>
        reserviertVon !== '' && reserviertVon !== null && reserviertVon !== username,
    )

  const showOrt = selectedOrt === myReservationsText

  return (
    <div
      style={{
        backgroundColor: colors.pageBackground,
        color: colors.text.default,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        zoom: '150%',
      }}
    >
      <Header
        lastUpdated={lastUpdated}
        retrigger={retrigger}
        orte={orte}
        selectedOrt={selectedOrt}
        setOrt={setOrt}
        ortData={ortData}
        setShowReserved={setShowReserved}
        setShowDone={setShowDone}
        showDone={showDone}
        showReserved={showReserved}
        allData={allData}
      />
      {(isOrtDone || isRestReserved) && (
        <div
          style={{
            position: 'sticky',
            top: 10,
            backgroundColor: colors.pageBackground,
            zIndex: 1,
            display: 'flex',
            flexDirection: 'column',
            width: 'auto',
            justifyContent: 'center',
            rowGap: 8,
            alignItems: 'center',
            padding: 16,
          }}
        >
          <Text style={{ fontSize: '1.5em', color: colors.text.default, wordBreak: 'break-word' }}>
            {!doIHaveReservations && selectedOrt === myReservationsText
              ? translate({ translations, path: ['youHaveNoReservations'], passed: selectedOrt })
              : isRestReserved
              ? translate({ translations, path: ['yourWorkDone'], passed: selectedOrt })
              : translate({ translations, path: ['allWorkDone'], passed: selectedOrt })}
          </Text>
          {!showReserved && isRestReserved && (
            <button
              onClick={() => {
                setShowReserved(true)
                setShowDone(false)
              }}
            >
              {translate({ translations, path: ['showReservedByOther'] })}
            </button>
          )}
        </div>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '2px',
        }}
      >
        {ortDataForUser?.map(voter => (
          <Line
            key={voter.id}
            {...voter}
            retrigger={retrigger}
            username={username}
            authToken={authToken}
            showOrt={showOrt}
          />
        ))}
      </div>
    </div>
  )
}
