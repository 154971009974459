import * as React from 'react'
import { BaseIcon } from './'

export const Pending = (props: Omit<React.ComponentProps<typeof BaseIcon>, 'rotate'>) => (
  <BaseIcon {...props} rotate>
    <circle r="2" cx="6" cy="6" fill="currentcolor" />
    <path
      d="m1 6 a5 5 0 0 0 5 5"
      fill="none"
      stroke="currentcolor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </BaseIcon>
)
