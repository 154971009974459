import * as React from 'react'

const getHashParam = (hash: string, keyName: string) =>
  new URLSearchParams(hash.replace('#', '?')).get(keyName) ?? undefined

export const useHashParam = (keyName: string) => {
  const [value, setValue] = React.useState<string>()
  React.useEffect(() => {
    setValue(getHashParam(document.location.hash, keyName))

    const locationHashChanged = (event: HashChangeEvent) =>
      setValue(getHashParam('#' + event.newURL.split('#')[1], keyName))
    window.addEventListener('hashchange', locationHashChanged)
    return () => window.removeEventListener('hashchange', locationHashChanged)
  }, [keyName])

  const setParamValue = (newValue: string) => {
    const searchParams = new URLSearchParams('?' + document.location.hash.replace('#', ''))
    searchParams.set(keyName, newValue)
    document.location.hash = searchParams.toString()
    setValue(newValue)
  }

  return [value, setParamValue] as const
}
