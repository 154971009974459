export const en = {
  gw: 'Municipality election visitation list',
  gwUser: 'Choose your user',
  username: 'username',
  gwLoginError: 'Username or password are wrong',
  password: 'password',
  refresh: 'Refresh',
  refreshed: 'Refreshed',
  choose: 'Choose',
  showVisited: 'Show visited',
  showReservedByOther: 'Show other reservations',
  yourWorkDone: (place: string) => `Your work in ${place} is done!`,
  allWorkDone: (place: string) =>
    `${place === en.myReservations ? place + ' are' : place + ' is'} done!`,
  'eu-citizen': 'EU-citizen',
  reservedBy: 'reserved by',
  reserve: 'reserve',
  visitWasted: 'visit wasted',
  visitNeeded: 'visit needed',
  showMyReservations: (count: number) => `Show my reservations (${count})`,
  otherReservations: 'Other reservations',
  youHaveNoReservations: 'You have no reservations',
  myReservations: 'My reservations',
}

type Translation = typeof en

export const de: Translation = {
  gw: 'Gemeinderatswahl Hausbesuche Liste',
  gwUser: 'Benutzer wählen',
  username: 'Benutzername',
  gwLoginError: 'Benutzername oder Passwort sind falsch',
  password: 'Passwort',
  refresh: 'Aktualisieren',
  refreshed: 'Aktualisiert',
  choose: 'Wähle',
  showVisited: 'Zeige besuchte',
  showReservedByOther: 'Zeige reservierungen Anderer',
  yourWorkDone: (place: string) => `Deine Arbeit in ${place} ist erledigt!`,
  allWorkDone: (place: string) =>
    `${place === de.myReservations ? place + ' sind' : place + ' ist'} erledigt!`,
  'eu-citizen': 'EU-Bürger',
  reservedBy: 'reserviert von',
  reserve: 'reservieren',
  visitWasted: 'Besuchen unnötig',
  visitNeeded: 'Besuchen notwendig',
  showMyReservations: (count: number) => `Zeige meine Reservierungen (${count})`,
  otherReservations: 'Andere Reservierungen',
  youHaveNoReservations: 'Du hast keine Reservierungen',
  myReservations: 'Meine Reservierungen',
}
