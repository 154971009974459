import * as React from 'react'
import { useLocation } from 'react-router-dom'
import { chunk } from 'lodash'
import { Spacer } from '../../elementals/Spacer'
import { Page } from '../../elementals/Page'
import { LinkButton } from '../../elementals/Button'
import stationsAny from './stations.json'

import QRCode from 'qrcode'

type Location = { lat: number; lon: number }
type OEBBInfo = { stationId: string; location: Location }

const stations = stationsAny as Array<[string, string, OEBBInfo]>

export const Bushaltestellen = () => {
  const currentRoute = useLocation().pathname
  const selectedStation = currentRoute.replace('/bh/', '')

  React.useEffect(() => {
    if (selectedStation !== '') {
      const currentStation = stations.find(([, route]) => route === currentRoute)
      if (currentStation) {
        window.location.href = getStationURL(currentStation[2].stationId)
      }
    }
  }, [selectedStation, currentRoute])

  const chunkedStations = chunk(stations, 4)

  return (
    <>
      <Page>
        <Spacer space={8} style={{ flexWrap: 'wrap' }}>
          {stations.map(([label, route, { stationId }]) => (
            <LinkButton
              key={route}
              style={{ marginBottom: '0.5em' }}
              href={getStationURL(stationId)}
            >
              {label}
            </LinkButton>
          ))}
        </Spacer>
      </Page>
      <div
        style={{
          breakBefore: 'always',
        }}
      />
      {chunkedStations.map(stationsStations => (
        <div
          style={{
            breakBefore: 'always',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}
        >
          {stationsStations.map(([label, route, { stationId }], index) => {
            const httpRoute = 'https://danielbartsch.name' + route
            const readableRoute = (
              <>
                danielbartsch.name
                <br />
                {route}
              </>
            )

            return (
              <div
                style={{
                  fontFamily: 'arial, helvetica, verdana',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  paddingBottom: 50,
                  paddingTop: 50,
                  paddingLeft: 30,
                  paddingRight: 30,
                  //border: '1px solid grey',
                }}
              >
                <div
                  style={{
                    fontSize: '1.75em',
                  }}
                >
                  Live-Fahrplan
                </div>
                <QR value={httpRoute} width="250" alt={label} />
                <div style={{ fontFamily: 'OpenDyslexicMono', fontSize: '1em' }}>
                  {readableRoute}
                </div>
              </div>
            )
          })}
        </div>
      ))}
    </>
  )
}

const QR = ({
  value,
  alt,
  ...props
}: { value: string } & Omit<React.ComponentProps<'img'>, 'alt'> &
  Required<Pick<React.ComponentProps<'img'>, 'alt'>>) => {
  const [img, setImg] = React.useState<string>()
  React.useEffect(() => {
    QRCode.toDataURL(value, { errorCorrectionLevel: 'Q' }).then((dataImg: string) => {
      setImg(dataImg)
    })
  }, [value])

  return <img src={img} alt={alt} {...props} />
}

const getStationURL = (stationId: string) =>
  `https://fahrplan.oebb.at/bin/stboard.exe/dn?L=vs_liveticker&evaId=${stationId}&boardType=dep&productsFilter=1011111111011&dirInput=&tickerID=dep&start=yes&eqstops=false&showJourneys=16&additionalTime=0`

/*const getDistance = (a: Location, b: Location) => {
  const radius = 6371
  const lat1 = degreesToRadians(a.lat)
  const lat2 = degreesToRadians(b.lat)
  const deltaLat = lat2 - lat1
  const deltaLon = degreesToRadians(b.lon) - degreesToRadians(a.lon)
  const f =
    Math.sin(deltaLat / 2) ** 2 + Math.cos(lat1) * Math.cos(lat2) * Math.sin(deltaLon / 2) ** 2
  const c = 2 * Math.atan2(Math.sqrt(f), Math.sqrt(1 - f))
  return radius * c // Haversine distance
}

const degreesToRadians = (degrees: number) => degrees * (Math.PI / 180)
*/
