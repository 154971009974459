export const getLocale = () => navigator.language

export const getLanguage = () => {
  const language = getLocale().split('-')[0]

  if (language === 'de' || language === 'en') {
    return language
  }

  return 'de'
}
