export const colors = {
  lineBackground: '#EEFE',
  lineBackgroundDone: '#FFF',
  pageBackground: '#FFF',
  text: {
    strasse: '#700E',
    hausnummer: '#070E',
    adresszusatz: '#22AE',
    alter: '#660E',
    eu: '#950E',
    besuchenUnnötig: '#e00E',
    besuchenNotwendig: '#CF0E',
    besuchte: '#1A1',
    user: '#14B',
    default: '#223',
  },
} as const

/*
  const darkmodeColors = {
    lineBackground: '#FFF1',
    lineBackgroundDone: '#000',
    pageBackground: '#000',
    text: {
      strasse: '#A77A',
      hausnummer: '#7A7A',
      adresszusatz: '#77AA',
      alter: '#AA7A',
      eu: '#FC0A',
      besuchenUnnötig: '#f77A',
      besuchenNotwendig: '#CF0E',
      besuchte: '#5C5',
      user: '#36A',
      default: '#aab',
    },
  } as const
  */
