import * as React from 'react'
import { ContentBox } from '../../elementals/ContentBox'
import { Button } from '../../elementals/Button'
import { Text } from '../../elementals/Text'
import michelhausenReference from './Michelhausen.webp'
import rustReference from './Rust.webp'
import michelndorfReference from './Michelndorf.webp'
import spitalReference from './Spital.webp'
import streithofenReference from './Streithofen.webp'
import atzelsdorfReference from './Atzelsdorf.webp'
import pixendorfReference from './Pixendorf.webp'
import mitterndorfReference from './Mitterndorf.webp'
import {
  atzelsdorf,
  michelhausen,
  michelndorf,
  mitterndorf,
  pixendorf,
  Point,
  rust,
  spital,
  streithofen,
} from './points'
import { uniqBy } from 'lodash'
import { Page } from '../../elementals/Page'

const PointRep = ({ point: [x, y] }: { point: Point }) => (
  <>
    P({x}|{y})
  </>
)

const getDistance = (pointA: Point, pointB: Point): number =>
  Math.sqrt(Math.abs(pointA[0] - pointB[0]) ** 2 + Math.abs(pointA[1] - pointB[1]) ** 2)

const getSum = (numberArray: Array<number>): number =>
  numberArray.reduce((prev, number) => prev + number, 0)

const List = <T,>({
  list,
  children,
  more,
  max = 10,
}: {
  list: Array<T>
  children: (listItem: T, index: number, array: Array<T>) => JSX.Element
  more: (remaining: number) => JSX.Element
  max?: number
}) => (
  <>
    {list.slice(0, max).map(children)}
    {list.length - max > 0 ? more(list.length - max) : undefined}
  </>
)

const getNearestPointIndex = (point: Point, points: Array<Point>) => {
  const distancesToPoint = points.map(otherPoint => getDistance(otherPoint, point))
  const smallestDistance = Math.min(...distancesToPoint)
  return distancesToPoint.findIndex(distance => distance === smallestDistance)
}

let showConnections = false

const maps: Array<[string, Array<Point>, string, number]> = [
  ['Michelhausen', michelhausen, michelhausenReference, 1107],
  ['Pixendorf', pixendorf, pixendorfReference, 476],
  ['Rust', rust, rustReference, 439],
  ['Atzelsdorf', atzelsdorf, atzelsdorfReference, 335],
  ['Michelndorf', michelndorf, michelndorfReference, 236],
  ['Mitterndorf', mitterndorf, mitterndorfReference, 231],
  ['Streithofen', streithofen, streithofenReference, 168],
  ['Spital', spital, spitalReference, 121],
  ['Select...', [], '', NaN],
]

let selectedMap = maps[0]
let selectedPointIndex = -1

export const PopulationDensityExperiment = () => {
  const [townName, townPoints, townImage, townPopulation] = selectedMap
  const allConnectionsAndAlsoDuplicates = townPoints.flatMap((_, index1) =>
    townPoints.flatMap(
      (_, index2): Array<[number, number]> => (index1 !== index2 ? [[index1, index2]] : []),
    ),
  )

  const pairs = uniqBy(
    allConnectionsAndAlsoDuplicates.map(([index1, index2]) => ({
      points: [index1, index2],
      uniqFactor: (townPoints[index1][0] < townPoints[index2][0]
        ? [index2, index1]
        : [index1, index2]
      ).join(),
    })),
    'uniqFactor',
  ).map(({ points }) => points)

  const [xSum, ySum] = townPoints.reduce(
    (prev, [x, y]) => {
      prev[0] += x
      prev[1] += y
      return prev
    },
    [0, 0],
  )
  const geographicalAverage = [xSum / townPoints.length, ySum / townPoints.length]

  const avgDistance =
    getSum(
      pairs.map(([pointAIndex, pointBIndex]) =>
        getDistance(townPoints[pointAIndex], townPoints[pointBIndex]),
      ),
    ) / pairs.length

  const avgDistanceOfSelectedPoint =
    selectedPointIndex !== -1
      ? getSum(
          townPoints.map((point, index) =>
            index === selectedPointIndex ? 0 : getDistance(point, townPoints[selectedPointIndex]),
          ),
        ) / townPoints.length
      : null

  let context: CanvasRenderingContext2D
  let gameLoopInterval: NodeJS.Timeout

  const canvasRef = React.useRef<HTMLCanvasElement>(null)

  const gameLoop = (context: CanvasRenderingContext2D) => {
    // removes everything drawn on the canvas
    // eslint-disable-next-line no-self-assign
    context.canvas.width = context.canvas.width

    if (showConnections || selectedPointIndex !== -1) {
      context.strokeStyle = '#44a2'
      if (selectedPointIndex === -1) {
        pairs.forEach(([pointAIndex, pointBIndex]) => {
          const pointA = townPoints[pointAIndex]
          const pointB = townPoints[pointBIndex]
          context.beginPath()
          context.moveTo(pointA[0], pointA[1])
          context.lineTo(pointB[0], pointB[1])
          context.stroke()
        })
      } else {
        const selectedPoint = townPoints[selectedPointIndex]
        townPoints.forEach(point => {
          context.beginPath()
          context.moveTo(point[0], point[1])
          context.lineTo(selectedPoint[0], selectedPoint[1])
          context.stroke()
        })
      }
    }
    townPoints.forEach(([x, y], index) => {
      if (index === selectedPointIndex) {
        context.fillStyle = '#0ff'
      } else {
        context.fillStyle = '#a44'
      }
      context.beginPath()
      context.fillRect(x - 2, y - 2, 4, 4)
    })

    context.fillStyle = '#ff4'
    context.beginPath()
    context.fillRect(geographicalAverage[0] - 3, geographicalAverage[1] - 3, 6, 6)
  }

  React.useEffect(() => {
    context = canvasRef?.current?.getContext('2d') as CanvasRenderingContext2D

    const refreshListener = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        selectedMap[1] = []
      }
      if (event.key === 'c') {
        if (selectedPointIndex !== -1) {
          selectedPointIndex = -1
          showConnections = false
        } else {
          showConnections = !showConnections
        }
      }
      if (event.key === 'd') {
        selectedMap[1].pop()
      }
      rerender(Math.random())
    }
    document.addEventListener('keydown', refreshListener)
    const clickListener = (event: MouseEvent) => {
      const point: Point = [event.offsetX, event.offsetY]
      if (event.ctrlKey) {
        selectedPointIndex = getNearestPointIndex(point, townPoints)
      } else {
        selectedMap[1].push(point)
      }
      rerender(Math.random())
    }
    canvasRef?.current?.addEventListener('click', clickListener)
    gameLoopInterval = setInterval(() => gameLoop(context), 16)

    return () => {
      clearInterval(gameLoopInterval)
      canvasRef?.current?.removeEventListener('click', clickListener)
      document.removeEventListener('keydown', refreshListener)
    }
  })

  const [, rerender] = React.useState(0)
  const [devInfo, setDevInfo] = React.useState(false)

  return (
    <Page>
      <Button
        onClick={() => {
          selectedMap[1] = []
          rerender(Math.random())
        }}
      >
        Reset (Esc)
      </Button>
      <select
        onChange={event => {
          const toBeLoadedMap = maps.find(([name]) => name === event.target.value)
          if (toBeLoadedMap) {
            selectedMap = toBeLoadedMap
            selectedPointIndex = -1
            rerender(Math.random())
          }
        }}
      >
        {maps.map(([name]) => (
          <option key={name} value={name}>
            {name}
          </option>
        ))}
      </select>
      <Button
        onClick={() => {
          showConnections = !showConnections
          rerender(Math.random())
        }}
      >
        Toggle connections (C)
      </Button>
      <Button
        onClick={() => {
          selectedMap[1].pop()
          rerender(Math.random())
        }}
      >
        Delete last point (D)
      </Button>
      <Button
        onClick={() => {
          setDevInfo(prev => !prev)
        }}
      >
        Toggle dev info
      </Button>
      <div>
        <img
          src={townImage}
          alt={townName}
          width={500}
          height={500}
          style={{ objectFit: 'contain' }}
        />
        <canvas
          ref={canvasRef}
          width={500}
          height={500}
          style={{ position: 'absolute', left: 0 }}
        />
      </div>
      {avgDistance ? (
        <>
          <p>
            {townName} has a population of{' '}
            <Text title={'according to Statistik Austria, 2018-01-01'}>{townPopulation}</Text>.
          </p>
          <p>The yellow dot is the geographical average of all dots.</p>
          {avgDistanceOfSelectedPoint !== null ? (
            <p>
              The average distance of the selected dot to all other dots is{' '}
              {avgDistanceOfSelectedPoint.toFixed(3)}
            </p>
          ) : (
            undefined
          )}
          <p>
            The points of {townName} are on average {avgDistance.toFixed(3)} units away. (
            {townPoints.length} points processed)
          </p>
          <p
            style={{
              marginLeft: '0.5em',
              fontSize: '0.75em',
            }}
          >
            Note: The average unit distance is not comparable between towns, as scales differ, and
            also the bigger the town, the bigger this value. If the value becomes bigger upon adding
            a point, it means that either the town is geographically growing, and/or that placement
            isn't well integrated into the town.
          </p>
        </>
      ) : (
        undefined
      )}
      {devInfo ? (
        <>
          {townPoints.length > 0 ? (
            <>
              <div>Given the points</div>
              <ul>
                <List
                  list={townPoints}
                  more={remaining => <li>...and {remaining} more</li>}
                  max={3}
                >
                  {([x, y], index) => (
                    <li key={index}>
                      P({x}|{y})
                    </li>
                  )}
                </List>
              </ul>
              <div>And the pairs</div>
              <ul>
                <List list={pairs} max={3} more={remaining => <li>...and {remaining} more</li>}>
                  {([pointAIndex, pointBIndex], index) => (
                    <li key={index}>
                      <PointRep point={townPoints[pointAIndex]} />
                      {' --> '}
                      <PointRep point={townPoints[pointBIndex]} />
                    </li>
                  )}
                </List>
              </ul>
            </>
          ) : (
            undefined
          )}
          <ContentBox title="JSON Points">{JSON.stringify(townPoints)}</ContentBox>
        </>
      ) : (
        undefined
      )}
    </Page>
  )
}
