import * as React from 'react'

const sizes = {
  normal: '1em',
  big: '1.25em',
} as const

type Size = keyof typeof sizes

type Props = Omit<React.ComponentProps<'svg'>, 'className' | 'rotate'> & {
  size?: Size
  rotate?: boolean
}

export const BaseIcon = ({ children, size = 'normal', rotate, ...props }: Props) => (
  <svg
    viewBox="0 0 12 12"
    width={sizes[size]}
    height={sizes[size]}
    className={rotate ? 'rotate' : undefined}
    {...props}
  >
    {children}
  </svg>
)
