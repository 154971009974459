import * as React from 'react'
import { Checkbox } from '../../elementals/Chooser'
import { colors } from './colors'
import { Text } from '../../elementals/Text'
import { xhr } from '../../../xhr'
import { Voter } from './types'
import * as translations from './translations'
import { translate } from '../../../utils/translations'
import { Pending } from '../../elementals/Icons/Pending'

export const Line = ({
  sprengel,
  nummer,
  istAbgehandeltWorden,
  id,
  strasse,
  hausnummer,
  adresszusatz,
  istEU,
  reserviertVon,
  geburtsjahr,
  ort,
  besuchenNotwendig,
  name,
  retrigger,
  showOrt,
  username,
  authToken,
}: Voter & {
  retrigger: () => void
  username: string | undefined
  authToken: string | undefined
  showOrt: boolean
}) => {
  const [isReservedPending, setReservedPending] = React.useState(false)
  const [isLineDonePending, setLineDonePending] = React.useState(false)

  const userOrNobodyReserved =
    username && (reserviertVon === username || reserviertVon === null || reserviertVon === '')
  return (
    <div
      title={`Sprengel ${sprengel}, Nummer ${nummer}`}
      style={{
        ...(istAbgehandeltWorden === 1 ? { opacity: 0.5 } : undefined),
        lineHeight: 1.3,
        backgroundColor:
          istAbgehandeltWorden === 1 ? colors.lineBackgroundDone : colors.lineBackground,
        borderRadius: 4,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 4,
        paddingBottom: 4,
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
      }}
    >
      <Checkbox
        style={{ flexGrow: 1 }}
        id={`abgehandelt-${id}`}
        disabled={!userOrNobodyReserved}
        checked={istAbgehandeltWorden === 0 ? false : true}
        icon={isLineDonePending ? <Pending /> : undefined}
        onChange={async event => {
          setLineDonePending(true)
          await xhr.patch(
            '/api/voters',
            { id, istAbgehandeltWorden: event.target.checked },
            { headers: { Authorization: 'Bearer ' + authToken } },
          )
          await retrigger()
          setLineDonePending(false)
        }}
      >
        {showOrt && <Text>{ort} </Text>}
        <Text style={{ color: colors.text.strasse }}>{strasse} </Text>
        <Text style={{ color: colors.text.hausnummer }}>
          {hausnummer}
          {adresszusatz ? '/' + adresszusatz : ''}
        </Text>
        <Text> {name} </Text>
        <Text style={{ color: colors.text.alter }}>~{new Date().getFullYear() - geburtsjahr}</Text>
        {istEU === 1 && (
          <>
            {' '}
            <Text style={{ color: colors.text.eu, whiteSpace: 'nowrap' }}>
              {translate({ translations, path: ['eu-citizen'] })}
            </Text>
          </>
        )}
        {istAbgehandeltWorden === 0 && besuchenNotwendig !== 0 && !userOrNobodyReserved && (
          <>
            {' '}
            {translate({ translations, path: ['reservedBy'] })}{' '}
            <Text style={{ color: colors.text.besuchenUnnötig }}> {reserviertVon}</Text>
          </>
        )}
      </Checkbox>
      {istAbgehandeltWorden === 0 && besuchenNotwendig !== 0 && (
        <>
          {userOrNobodyReserved && (
            <Checkbox
              checked={(reserviertVon ?? '') !== ''}
              icon={isReservedPending ? <Pending /> : undefined}
              onChange={async event => {
                setReservedPending(true)
                await xhr.patch(
                  '/api/voters',
                  { id, reserviertVon: event.target.checked ? username : '' },
                  { headers: { Authorization: 'Bearer ' + authToken } },
                )
                await retrigger()
                setReservedPending(false)
              }}
            >
              {reserviertVon ? (
                <>
                  {translate({ translations, path: ['reservedBy'] })}{' '}
                  <Text style={{ color: colors.text.user }}>{reserviertVon}</Text>
                </>
              ) : (
                translate({ translations, path: ['reserve'] })
              )}
            </Checkbox>
          )}
        </>
      )}
      {besuchenNotwendig === 0 && (
        <Text style={{ color: colors.text.besuchenUnnötig, verticalAlign: 'top' }}>
          {translate({ translations, path: ['visitWasted'] })}
        </Text>
      )}
      {besuchenNotwendig === 1 && (
        <Text style={{ color: colors.text.besuchenNotwendig, verticalAlign: 'top' }}>
          {translate({ translations, path: ['visitNeeded'] })}
        </Text>
      )}
    </div>
  )
}
