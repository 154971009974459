import * as React from 'react'
import { paths } from '../../../paths'
import { NavigationButton } from '../../elementals/NavigationButton'
import { Page } from '../../elementals/Page'
import { Spacer } from '../../elementals/Spacer'
import { CheerButton } from '../../features/CheerButton'
import Me1 from './01.webp'
import Me2 from './02.webp'

const subPaths = ['/curriculumVitae', '/votePrompts', '/games', '/bh', '/torii']

export const Home = () => (
  <>
    <br />
    <Spacer space={8} style={{ flexWrap: 'wrap' }}>
      {paths
        .filter(([path]) => subPaths.includes(path))
        .map(([path, label]) => (
          <NavigationButton path={path} key={path} style={{ marginBottom: '0.5em' }}>
            {label}
          </NavigationButton>
        ))}
    </Spacer>
    <Page>
      <h1>Hallo, Servus!</h1>
      <Spacer style={{ marginRight: 16, float: 'left', flexWrap: 'wrap' }} space="none">
        <img src={Me1} alt="me 1" width="125" />
        <img src={Me2} alt="me 2" width="125" />
      </Spacer>
      <p>
        Ich heiße Daniel Bartsch, und wohne in der Gemeinde Michelhausen im Ortsteil Atzelsdorf seit
        fast meiner Geburt!
      </p>
      <p>
        Ich mache beim <a href="https://www.team-michelhausen.at/">Team Michelhausen</a> mit, ein
        bisschen nachdem ich begonnen habe mich für meinen Wohnort zu interessieren (seit ca. 2019).
      </p>
      <p>
        Ich fahre leidenschaftlich gerne Fahrrad. Meine Frau ist wohl der Meinung, dass das mein
        Leben bestimmt, und da hat sie nicht ganz unrecht. Ich besitze ein Auto, fahre damit aber
        sehr selten und ungern. Überall wo ich kann, benutze ich mein Rad zum pendeln (ich bin kein
        über-drüber Sportler).
      </p>
      <p>
        Gerne bin ich in meiner Gemeinde außerhalb des Autos unterwegs. So kann ich die Natur
        genießen, ungestört mit meiner Frau reden, und den Leuten draußen ins Gesicht schauen.
      </p>
      <p>
        Leider ist das nicht möglich, wenn Menschen innerhalb von Autos sind. Schnell sind sie
        vorbei, die Windschutzscheibe spiegelt, man sieht die Menschen nicht, man muss sich das
        Kennzeichen merken, um sie voneinander zu unterscheiden. Das ist mir zu unpersönlich.
      </p>
      <p>
        Ich schweife ab, viel Spaß auf meiner Seite!{' '}
        <NavigationButton path="/radweg" style={{ display: 'inline-flex' }}>
          Hier
        </NavigationButton>{' '}
        geht's zu meiner Initiative, den langen Radweg von Michelhausen nach fast Judenau etwas
        besser zu machen.
      </p>

      <Spacer
        orientation="vertical"
        alignment="center"
        style={{ width: '100%', alignItems: 'center' }}
      >
        <CheerButton topic="home" />
      </Spacer>
    </Page>
  </>
)
