import * as React from 'react'
import { RouteProps, Link } from 'react-router-dom'
import { VotePrompt } from './types'
import { Prompt } from './Prompt'
import { CreatePrompt } from './CreatePrompt'
import { Spacer } from '../../elementals/Spacer'
import { Button } from '../../elementals/Button'
import { Plus } from '../../elementals/Icons/Plus'
import { Left } from '../../elementals/Icons/Left'
import { xhr } from '../../../xhr'
import { ContentBox } from '../../elementals/ContentBox'
import { formatConvenient } from '../../../utils/date'
import { translate } from '../../../utils/translations'
import * as translations from './translations'
import { Page } from '../../elementals/Page'

export const VotePrompts = ({ location }: RouteProps) => {
  const prompt_id = Number.parseInt(location?.search?.replace('?', '') ?? '')
  const isSpecificPrompt = Number.isFinite(prompt_id)

  const [prompts, setPrompts] = React.useState<Array<VotePrompt>>([])
  const [isCreating, setIsCreating] = React.useState(false)

  React.useEffect(() => {
    xhr
      .get(`/api/vote${isSpecificPrompt ? `?${prompt_id}` : ''}`)
      .then(({ data }: { data: Array<VotePrompt> }) =>
        setPrompts(
          data.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()),
        ),
      )
  }, [prompt_id, isSpecificPrompt])

  return (
    <Page>
      <div
        style={{
          display: 'flex',
          position: 'relative',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        {isSpecificPrompt && location?.pathname && (
          <div style={{ position: 'absolute', left: 0 }}>
            <Link to={location.pathname}>
              <Button data-type="primary">
                <Left />
              </Button>
            </Link>
          </div>
        )}
        <Button
          data-type="primary"
          onClick={() => setIsCreating(true)}
          style={{
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '20%',
            minWidth: '2em',
            height: '2em',
            marginBottom: '1.5em',
          }}
        >
          <Plus size="big" />
        </Button>
      </div>
      <Spacer space={16} orientation="vertical">
        {isCreating && <CreatePrompt setIsCreating={setIsCreating} setPrompts={setPrompts} />}
        {prompts.map(prompt => (
          <Prompt key={prompt.prompt_id} {...prompt} setPrompts={setPrompts} />
        ))}
        {isSpecificPrompt && prompts.length === 0 && (
          <ContentBox
            title={
              <Spacer space="all" style={{ fontSize: '1.25em' }}>
                <div>
                  {translate({
                    translations,
                    path: ['prompt404', 'sorry'],
                  })}
                </div>
                <div>{formatConvenient(new Date())}</div>
              </Spacer>
            }
          >
            <span style={{ fontSize: '1.5em' }}>
              {translate({
                translations,
                path: ['prompt404', 'notFound'],
              })}
            </span>
          </ContentBox>
        )}
      </Spacer>
    </Page>
  )
}
